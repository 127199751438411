import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Globals } from './globals'

const apiBrandGetMethod = "getUserBrands";

@Injectable({
    providedIn: 'root'
})
export class UserBrandProvider {
    data: any = {};

    constructor(
        public http: HttpClient, 
        public globals: Globals
    ) {}

    load(refresh): any {

        if(this.globals.brandId != "" && !refresh){
            return of({"brands" :[{brandId: this.globals.brandId }]});
        }
        else if (!this.globals.isEmpty(this.data) && !refresh) {
            return of(this.data);
        } else {

            try{
                let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
                headers = headers.set("Content-Type", "application/json; charset=utf-8");

                return this.http
                .post<any>(this.globals.apiBaseUrl + "?o=" + apiBrandGetMethod, { username: "" }, { headers: headers })
                .pipe(map(this.processData, this))
                .pipe(catchError(this.globals.handleHttpError));
            }
            catch(ex){
                this.globals.logout();
                return false;
            }

        }
    }

    processData(data: any) {
        this.data = data.response;
        return this.data;
    }

    getUserInfo(refresh){
        return this.load(refresh).pipe(map((data:any) => {
            return data;
        }));
    }

    getRole(refresh){
        return this.load(refresh).pipe(map((data:any) => {
            return data.roles;
        }));
    }
}