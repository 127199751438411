import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Globals } from './globals'

//legacy api method
const apiBrandGetMethod = "getBrand";

//updated api method
const apiBrandsGetMethod = "getBrands";

@Injectable({
    providedIn: 'root'
})
export class BrandProvider {
    data: any = {};

    constructor(
        public http: HttpClient, 
        public globals: Globals
    ) {}

    load(refresh): any {
        if (!this.globals.isEmpty(this.data) && !refresh) {
            return of(this.data);
        } else {

            try{

                let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
                headers = headers.set("Content-Type", "application/json; charset=utf-8");

                return this.http
                .post<any>(this.globals.apiBaseUrl + "?o=" + apiBrandGetMethod, { brandId: this.globals.brandId }, { headers: headers })
                .pipe(map(this.processData, this))
                .pipe(catchError(this.globals.handleHttpError));
            }
            catch(ex){
                this.globals.logout();
                return false;
            }

        }
    }

    processData(data: any) {
        this.data = data.response;
        return this.data;
    }

    getBrandInfo(refresh){
        return this.load(refresh).pipe(map((data:any) => {
            return data.brand;
        }));
    }

    getBrands(all): any {
        try{

            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            var body = {};
            if(!all){
              body = {
                  brandId : this.globals.brandId
              }
            }

            return this.http
            .post<any>(this.globals.apiBrandUrl + "?o=" + apiBrandsGetMethod, body, { headers: headers })
            .pipe(map(this.processData, this))
            .pipe(catchError(this.globals.handleHttpError));
        }
        catch(ex){
            this.globals.logout();
            return false;
        }

    }
    
}