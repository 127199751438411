import { Injectable } from '@angular/core';
import { AnimationSet } from '../interfaces/animationset';
import { ContentSet } from '../interfaces/contentset';
import { Dataset } from '../interfaces/dataset';
import { VideoFormat } from '../interfaces/videoformat';
import { ContentProvider } from '../providers/content-provider'
import { AnimationProvider } from './animation-provider';
import { DatasetProvider } from './dataset-provider';
import { StorageService } from './storage-service';

@Injectable({
    providedIn: 'root'
})
export class ContentSetGlobal {
constructor(
  public contentProvider: ContentProvider,
  private animationProvider: AnimationProvider,
  private datasetProvider: DatasetProvider,
  private storageService: StorageService) {
  }

  CurrentContentSetVideo: ContentSet;
  CurrentContentSetVideoDatasets : Dataset[] = [];
  CurrentContentSetVideoDatasetsFlat : Dataset;
  videoFormats: VideoFormat[];
  data: Dataset[];
  animationSets: AnimationSet[];

  masterDataset: Dataset;
  masterDataLoading = true;

  exiting:boolean = false;
  saveMessage:string = "";
  loading = true;

  overallLoading = false;
  datasetLoading = false;

  load(){

    this.overallLoading = true;
    this.loading = true;

    return new Promise<void>(async (resolve, reject) => {

      //load local variables
      var localLoadPromise = this.loadFromLocal();

      //get video formats
      var formatPromise = new Promise<void>(async (innerresolve, reject) => {
        this.animationProvider.getVideoFormats().subscribe(response => {
          this.videoFormats = response;
          innerresolve()
        });
      });

      //return when all loaded
      Promise.all([localLoadPromise, formatPromise]).then(() => {
        resolve();
        this.overallLoading = false;

        if(!this.overallLoading && !this.datasetLoading)
          this.loading = false;
      });

    });
  }

  loadDatasets(){
    this.datasetLoading = true;
    this.loading = true;

    var datasetsLoaded = 0;

    // load full datasets of selected animation datasets
    this.CurrentContentSetVideo.animations.forEach((animation:any) => {
      this.datasetProvider.getDataset(animation.dataset.id, false, true).subscribe(result => {
        this.CurrentContentSetVideoDatasets = [];
        this.CurrentContentSetVideoDatasets.push(result[0]);
        datasetsLoaded++;

        //once all datasets are loaded
        if(datasetsLoaded == this.CurrentContentSetVideo.animations.length){

          //flatten datasets for mapping screen
          this.CurrentContentSetVideoDatasetsFlat = new Dataset();
          this.CurrentContentSetVideoDatasets.forEach(dataset => {
            dataset.fields.forEach(field => {
              field.name = dataset.name.trim() + " : " + field.name.trim();
            })
            this.CurrentContentSetVideoDatasetsFlat.fields = this.CurrentContentSetVideoDatasetsFlat.fields.concat(dataset.fields);
          })

          this.datasetLoading = false;
  
          //everything is loaded
          if(!this.overallLoading && !this.datasetLoading)
            this.loading = false;
        }

      });
    });
  }

  async storeLocally() {
    await this.storageService.set("CurrentContentSetVideo", this.CurrentContentSetVideo);
  }

  async loadFromLocal() {
    return new Promise<void>(async (resolve, reject) => {
      var currentContentSetVideo = await this.storageService.get("CurrentContentSetVideo");
      if (currentContentSetVideo != '')
        this.CurrentContentSetVideo = currentContentSetVideo;

      resolve();
    });
  }

  save() {
    return new Promise<boolean>(async (resolve, reject) => {

      this.storeLocally();

      //save content set
      this.contentProvider.saveContentSetVideo(this.CurrentContentSetVideo).subscribe(response => {
        if(response == "success"){
          resolve(true);
        }
        else{
          this.saveMessage = "Error saving : " + response;
          resolve(false);
        }
      });
    });
  }
}