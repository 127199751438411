import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Globals } from './globals'

@Injectable({
    providedIn: 'root'
})
export class AnimationProvider {
    animations: any = [];
    apiAnimationsGetMethod = "getAnimationSets";
    apiCheckSampleUploadedMethod = "checkSampleUploaded";
    apiAnimationsSaveMethod = "saveAnimationSet";
    apiinitiateAnimationSetUploadMethod = "initiateAnimationSetUpload";
    apiinitiateAnimationSampleUploadMethod = "initiateAnimationSampleUpload";
    apiGetAnimationSetTagsMethod = "getAnimationSetTags";
    apiGetVideoFormatsMethod = "getVideoFormats";
    apiAnimationsDeleteMethod = "deleteAnimationSet";

    constructor(
        public http: HttpClient, 
        public globals: Globals) {}

    load(animationId = null, published, refresh): any {
        
        //filter by campaign
        var localAnimations;
        if(animationId != null)
            localAnimations = this.animations.filter(a => a.id == animationId);
        else    
            localAnimations = this.animations;

        if (localAnimations.length > 0 && !refresh) {
            return of(localAnimations);
        } else {
            try{

                let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
                headers = headers.set("Content-Type", "application/json; charset=utf-8");

                var body = { 
                    brandId: this.globals.brandId, 
                    userId: this.globals.userId,
                    published: published 
                }

                return this.http
                .post<any>(this.globals.apiAnimationsUrl + "?o=" + this.apiAnimationsGetMethod, body, { headers: headers })
                .pipe(map(this.processData, this))
                .pipe(catchError(this.globals.handleHttpError));
            }
            catch(ex){
                this.globals.logout();
                return false;
            }
        }
    }

    processData(data: any) {
        this.animations = data.response;
        return this.animations;
    }

    getAnimationSets(animationId = null, published = true, refresh = true){
        return this.load(animationId, published, refresh).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getAnimationSetById(animationId, published, refresh){
        return this.getAnimationSets(animationId, published, refresh).pipe(map((data:any[]) => {
            return data.filter(r => r.id == animationId);
        }));
    }

    initiateAnimationSetUpload(data) : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            return this.http
            .post<any>(this.globals.apiAnimationsUrl + "?o=" + this.apiinitiateAnimationSetUploadMethod, data, { headers: headers })
            .pipe(map(this.processAnimationData, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    initiateAnimationSampleUpload(data) : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            return this.http
            .post<any>(this.globals.apiAnimationsUrl + "?o=" + this.apiinitiateAnimationSampleUploadMethod, data, { headers: headers })
            .pipe(map(this.processAnimationData, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    completeUpload(presignedPostObject, file) : any {

        try{

            var form = new FormData();
            Object.keys(presignedPostObject.fields).forEach(key => form.append(key, presignedPostObject.fields[key]));
            form.append('file', file);

            let headers = new HttpHeaders();

            return this.http
            .post<any>(presignedPostObject.url, form,  {
                reportProgress: true,
                observe: 'events',
                headers: headers})
            .pipe(map(this.processUploadEvents, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    processUploadEvents(event: any) {
        return event;
    }

    saveAnimationSet(animationSet, publish = false) : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            var body = {
                userId : this.globals.userId,
                animationSet: animationSet,
                publish : publish
            }

            return this.http
            .post<any>(this.globals.apiAnimationsUrl + "?o=" + this.apiAnimationsSaveMethod, body, { headers: headers })
            .pipe(map(this.processAnimationData, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    deleteAnimationSet(animationSetId, publish = false) : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            var body = {
                userId : this.globals.userId,
                id : animationSetId
            }

            return this.http
            .post<any>(this.globals.apiAnimationsUrl + "?o=" + this.apiAnimationsDeleteMethod, body, { headers: headers })
            .pipe(map(this.processAnimationData, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    processAnimationData(data: any) {
        return data.response;
    }

    processUploadComplete(data: any) {
        return "success";
    }

    getAnimationSetTags(data) : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            return this.http
            .post<any>(this.globals.apiAnimationsUrl + "?o=" + this.apiGetAnimationSetTagsMethod, data, { headers: headers })
            .pipe(map(this.processAnimationData, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    checkSampleUploaded(animationId, animationFormatId, sampleFileKey) : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            var body = {
                animationId : animationId,
                animationFormatId: animationFormatId,
                sampleFileKey : sampleFileKey
            }

            return this.http
            .post<any>(this.globals.apiAnimationsUrl + "?o=" + this.apiCheckSampleUploadedMethod, body, { headers: headers })
            .pipe(map(this.processAnimationData, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    getVideoFormats() : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            return this.http
            .post<any>(this.globals.apiAnimationsUrl + "?o=" + this.apiGetVideoFormatsMethod, {}, { headers: headers })
            .pipe(map(this.processAnimationData, this))
            .pipe(catchError(this.globals.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    handleHttpError = (error: HttpErrorResponse) => {
        let errorMessage = 'Unknown error!';
        if (error.error.type == "error" && error.error.err) {
            // Custom errors
            if(error.error.err.message)
                errorMessage = `Error: ${error.error.err.message}`;
            else
                errorMessage = `Error: ${error.error.err}`;
        }
        else if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } 
        else {
          // Server-side errors
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return of(errorMessage);
      }
}