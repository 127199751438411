import { Utils } from "../providers/utils"
import { DatasetField } from "./datasetfield";
import { Globals } from "./../providers/globals";


export class Dataset {
    id: string = "";
    name: string = "";
    brandId: string = "";
    userId: string = "";
    type:string = "";
    fields:DatasetField[] = [];
    data: object[] = [];

    master:boolean = false;
    created: number;
    updated:number;


    constructor() {
      if(!this.id){
        var utils = new Utils();
        this.id = utils.guid();
        this.created = Date.now();
      }
    }
  }